import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { DealersDealerOfficeListItem } from '@tes/apps-dto';
import { ProfileControlService } from '@global-services/profile-service/profile.service';
import { OfficeStorageService } from '../office-storage/office-storage.service';

class State {
  loading: boolean = false;
}

@Injectable({ providedIn: 'root' })
export class CurrentOfficeService extends ComponentStore<State> {
  //текущие офисы, расчитываются на основе текущих дилеров и всего списка офисов
  public listOffices$: Observable<DealersDealerOfficeListItem[]> =
    this.officeStorageService.listDealers$;

  constructor(
    private profileService: ProfileControlService,
    private officeStorageService: OfficeStorageService
  ) {
    super(new State());

    this.profileService.profile$
      .pipe(
        map((profile) => profile?.email),
        distinctUntilChanged(),
        tap(() => this.loadOffices())
      )
      .subscribe();
  }

  //загразка списка офисов
  public loadOffices() {
    this.officeStorageService.loadOffices();
  }
}
