import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { buildHttpParams } from '@shared-services/apps-dto-request/apps-dto-request.class';
import { map, Observable } from 'rxjs';
import { DealerOfficeFindRequestDto } from '@shared-services/api/dealer-office/dtos/dealer-office-find.request.dto';
import { DealerOfficeFindResponseDto } from '@shared-services/api/dealer-office/dtos/dealer-office-find.response.dto';
import { DealerOfficesCityWithDealersResponseDto } from './dtos/dealer-offices-city-with-dealers-response.dto';
import { DealerOfficePutRequest } from './dtos/dealer-office-put.request';
import { DealersResponseDto } from './dtos/dealers.response.dto';

@Injectable({ providedIn: 'root' })
export class DealerOfficeApiService {
  constructor(private readonly http: HttpClient) {}

  public getOfficeList(): Observable<DealersResponseDto> {
    return this.http
      .get<{ data: DealersResponseDto }>('/tes-dealers/dealer-office/dealers')
      .pipe(map((response) => response.data));
  }

  find(
    params: DealerOfficeFindRequestDto
  ): Observable<DealerOfficeFindResponseDto> {
    return this.http
      .get<{ data: DealerOfficeFindResponseDto }>(
        '/tes-dealers/dealer-office',
        {
          params: buildHttpParams(params),
        }
      )
      .pipe(map((response) => response.data));
  }

  findAdmin(params: DealerOfficeFindRequestDto) {
    return this.http
      .get<{ data: DealerOfficeFindResponseDto }>(
        '/tes-dealers/admin/dealer-offices',
        {
          params: buildHttpParams(params),
        }
      )
      .pipe(map((response) => response.data));
  }

  getCityDealersList() {
    return this.http
      .get<{
        data: DealerOfficesCityWithDealersResponseDto;
      }>('/tes-dealers/dealer-office/city-dealers-list')
      .pipe(map((response) => response.data));
  }

  putDealerOffice(sapCode: string, data: DealerOfficePutRequest) {
    return this.http.put<void>(`/tes-dealers/dealer-office/${sapCode}`, data);
  }
}
