import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';

import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { catchError } from 'rxjs';
import { DealerOfficeApiService } from '@shared-services/api/dealer-office/dealer-office-api.service';
import { DealersDealerOfficeListItem } from '@tes/apps-dto';

export interface ParenDealerList {
  name: string;
  sapCode: string;
}

class State {
  offices: DealersDealerOfficeListItem[] = [];
  parentDealers: ParenDealerList[] = [];
  initialized: boolean = false;
}

@Injectable({ providedIn: 'root' })
export class OfficeStorageService extends ComponentStore<State> {
  //текущие дилеры
  public listDealers$ = this.select((e) => e.offices);
  //Только родительские
  public parentDealers$ = this.select((state) =>
    state.initialized ? state.parentDealers : []
  ).pipe(
    filter(() => this.get((state) => state.initialized)) // Испускаем данные только после инициализации
  );

  constructor(private readonly dealerOfficeApi: DealerOfficeApiService) {
    super(new State());
  }

  //загрузка списка офисов
  public loadOffices = this.effect((o: Observable<undefined>) =>
    o.pipe(
      tap(() => this.patchState({ initialized: false })),
      switchMap(() => this.updateOfficeList()),
      tap(() => this.patchState({ initialized: true }))
    )
  );

  private updateOfficeList() {
    return this.dealerOfficeApi.getOfficeList().pipe(
      tap((response) =>
        this.patchState({
          offices: response.offices,
          parentDealers: response.dealers.map((dealer) => ({
            name: dealer.name.name,
            sapCode: dealer.sapCode,
          })),
        })
      ),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      })
    );
  }
}
