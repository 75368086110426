import { ProfileControlService } from '@global-services/profile-service/profile.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { TokenControlService } from '@global-services/token-service/token-control.service';
import { CleanerControlService } from '@global-services/cleaner-service/cleaner-control.service';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { PROFILE_VERSION } from '@global-services/profile-service/shared/profile.interface';
import { LoginProfileResponse } from '@global-services/auth-service/auth.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { SetProfileAction } from '@global-services/profile-service/profile.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store,
    private tokenControlService: TokenControlService,
    private cleanerControlService: CleanerControlService,
    private profileControlService: ProfileControlService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuth();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuth();
  }

  checkAuth(): Observable<boolean> {
    return this.tokenControlService.currentAccessToken$.pipe(
      switchMap((token) => (token ? this.getProfile() : of(null))),
      tap(
        (profile) =>
          profile &&
          this.store.dispatch(
            new SetProfileAction({
              name: profile.name,
              email: profile.email,
              roles: profile.permissions,
              logoUrl: profile.logoUrl,
              version:
                this.profileControlService.profile?.version || PROFILE_VERSION,
            })
          )
      ),
      map((profile) => {
        if (!profile) {
          this.cleanerControlService.clean();
          this.router.navigate(['/login']);
          this.tokenControlService.removeToken();
          return false;
        }
        return !!profile;
      })
    );
  }

  protected getProfile(): Observable<LoginProfileResponse | null> {
    return this.http
      .get<{ data: LoginProfileResponse }>('/tes-dealers/auth/account')
      .pipe(
        map((response) => response.data),
        catchError(() => of(null))
      );
  }
}
